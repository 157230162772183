import { useContext, useEffect, useState } from 'react'
import { Table, Modal, Space } from 'antd'
import moment from 'moment'

import './styles.css'

import { Context } from '../../context'
import { Fetch } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import { CheckCircleFilled, CloseCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons'

const { Column } = Table

const ManageOrders = () => {
    const { token, apiUrl } = useContext(Context)

    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        Fetch(
            `${apiUrl}/orders`,
            token,
            (result) => {
                const orders = convertIdToKey(result.orders)
                setOrders(orders)
            }
        )
    }, [apiUrl, token])

    const recheckPayment = (id) => {
        setLoading(true)

        Fetch(
            `${apiUrl}/orders/${id}`,
            token,
            (result) => {
                if (result.paid) {
                    setOrders(orders.map(order => order.key == id ? { ...order, paid: true } : order))
                }
                setLoading(false)
            }
        )
    }

    return (
        <div className="manage-orders-wrapper">
            <Table dataSource={orders}>
                <Column title="ID" dataIndex="key" key="key" align="center" />
                <Column title="User" dataIndex="user" key="language" align="center" />
                <Column title="Date" dataIndex="date" key="date" align="center" defaultSortOrder='descend' sorter={(a, b) => moment(a.date) - moment(b.date)} />
                <Column title="Plan" dataIndex="plan" key="plan" align="center" />
                <Column title="Amount" dataIndex="amount" key="amount" align="center" render={(amount, record) => `₹ ${amount / 100}`} />
                <Column title="Discount Used" dataIndex="discountCode" key="discountCode" align="center" render={code => code === 'None' ? '-' : code} />
                <Column
                    title="Paid"
                    dataIndex="paid"
                    key="paid"
                    align="center"
                    filters={[{ text: 'Paid', value: true }]}
                    onFilter={(value, record) => record.paid === value}
                    render={paid => paid ? <CheckCircleFilled /> : <CloseCircleOutlined />}
                />
                <Column title="Actions" align="center" render={(_, record) => record.paid ? '-' : <a onClick={() => recheckPayment(record.key)} disabled={loading}>Recheck Status</a>} />
            </Table>
            <CloudDownloadOutlined className="export-btn-only" onClick={() => handleExportClick(apiUrl, token, 'orders')} />
        </div>
    )
}

export default ManageOrders
