import { Form, Input, Modal, Table, Radio, Select, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, CloudDownloadOutlined } from '@ant-design/icons'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'

const { Column } = Table
const { Option } = Select

const ManageArtists = () => {
    const { token, apiUrl } = useContext(Context)

    const [artists, setArtists] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [artistsMaster, setArtistsMaster] = useState([])
    const [counter, setCounter] = useState(0)

    const [form] = Form.useForm()

    useEffect(() => {
        Fetch(`${apiUrl}/artists`, token, (result) => {
            const artists = convertIdToKey(result.artists)
            setArtists(artists)
            setArtistsMaster(artists)
        })
    }, [apiUrl, token, counter])

    const handleSubmit = async () => {
        try {
            setConfirmLoading(true)
            await form.validateFields()
            Post(
                `${apiUrl}/artists`,
                token,
                {
                    name: form.getFieldValue('name'),
                    gender: form.getFieldValue('gender')
                },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Artist created'
                        })
                    }
                    form.resetFields()
                    setModalVisible(false)
                    setCounter(counter + 1)
                },
                () => {
                    notification.error({
                        message: 'Add Artist Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Add Artist Error',
                description: 'Required fields are incorrect/missing'
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    const handleDelete = async (id) => {
        try {
            await setConfirmLoading(true)
            Delete(
                `${apiUrl}/artists/${id}`,
                token,
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Artist deleted successfully'
                        })
                        setCounter(counter + 1)
                    }
                },
                () => {
                    notification.error({
                        message: 'Delete Artists Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } finally {
            setConfirmLoading(false)
        }
    }

    const handleSearchChange = (artistId) => {
        if (!artistId)
            return

        const a = artists.filter(a => a.key === artistId)
        setArtists(a)
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Search Artist"
                showSearch
                allowClear
                onChange={handleSearchChange}
                onClear={() => setArtists(artistsMaster)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {artistsMaster.map(a => (
                    <Option value={a.key}>{a.name}</Option>
                ))}
            </Select>
            <Table dataSource={artists}>
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Gender" dataIndex="gender" key="gender" align="center" />
                <Column
                    title="Actions"
                    key="action"
                    align="center"
                    render={(text, record) => (
                        <a onClick={() => handleDelete(record.key)}>Delete</a>
                    )}
                />
            </Table>
            <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-artist-btn" onClick={() => setModalVisible(true)} />
            <CloudDownloadOutlined className="export-btn" onClick={() => handleExportClick(apiUrl, token, 'artists')} />
            <Modal
                title="Add Artist"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Submit"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form form={form} layout="vertical" initialValues={{ gender: "M" }}>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value="M">Male</Radio>
                            <Radio value="F">Female</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ManageArtists
