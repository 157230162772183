export const Fetch = (url, authToken, callback) => {
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.status)
        }
        return response.json()
    }).then(result => {
        callback(result)
    }).catch(err => {
        console.error(err)
    })
}

export const Post = (url, authToken, data, callback, errCallback) => {
    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(data)
    }).then(response => {
        if (!response.status === 201) {
            throw new Error(response.status)
        }
        return response.json()
    }).then(result => {
        callback(result)
    }).catch(err => {
        console.error(err)
        errCallback()
    })
}

export const Put = (url, authToken, data, callback, errCallback) => {
    fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(data)
    }).then(response => {
        if (!response.status === 200) {
            throw new Error(response.status)
        }
        return response.json()
    }).then(result => {
        callback(result)
    }).catch(err => {
        console.error(err)
        errCallback()
    })
}

export const Patch = (url, authToken, data, callback, errCallback) => {
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(data)
    }).then(response => {
        if (!response.status === 200) {
            throw new Error(response.status)
        }
        return response.json()
    }).then(result => {
        callback(result)
    }).catch(err => {
        console.error(err)
        errCallback()
    })
}

export const Delete = (url, authToken, callback, errCallback) => {
    fetch(url, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${authToken}`
        },
    }).then(response => {
        if (!response.status === 200) {
            throw new Error(response.status)
        } else {
            return response.json()
        }
    }).then(result => {
        callback(result)
    }).catch(err => {
        console.error(err)
        errCallback()
    })
}
