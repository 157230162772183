import { Form, Input, Modal, Table, Space, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, ExclamationCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'

const { Column } = Table
const { confirm } = Modal

const ManageLanguages = () => {
    const { token, apiUrl } = useContext(Context)

    const [languages, setLanguages] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [counter, setCounter] = useState(0)

    const [form] = Form.useForm()

    useEffect(() => {
        Fetch(`${apiUrl}/languages`, token, (result) => {
            const languages = convertIdToKey(result.languages)
            setLanguages(languages)
        })
    }, [apiUrl, token, counter])

    const handleSubmit = async () => {
        try {
            setConfirmLoading(true)
            await form.validateFields()
            Post(
                `${apiUrl}/languages`,
                token,
                {
                    name: form.getFieldValue('name'),
                },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Language created'
                        })
                    }
                    form.resetFields()
                    setModalVisible(false)
                    setCounter(counter + 1)
                },
                () => {
                    notification.error({
                        message: 'Add Language Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Add Language Error',
                description: 'Required fields are incorrect/missing'
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    const confirmDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete this language?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/languages/${id}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Language deleted successfully'
                            })
                            setCounter(counter + 1)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting language',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    return (
        <div className="manage-genres-wrapper">
            <Table dataSource={languages}>
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column
                    title="Actions"
                    key="actions"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => confirmDelete(record.key)}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-genre-btn" onClick={() => setModalVisible(true)} />
            <CloudDownloadOutlined className="export-btn" onClick={() => handleExportClick(apiUrl, token, 'languages')} />
            <Modal
                title="Add Language"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Submit"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ManageLanguages
