
import {
    Space,
    Table,
    Tag,
    Modal,
    notification,
    Form,
    Input,
    InputNumber,
    Select,
    Card,
    Radio,
    DatePicker,
    Upload,
    message,
    Progress
} from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined, CloudDownloadOutlined, CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons'
import { useContext, useEffect, useState } from 'react'
import * as tus from 'tus-js-client'
import moment from 'moment'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Put, Delete, Post, Patch } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'

const { Column } = Table
const { confirm } = Modal
const { Option } = Select
const { TextArea } = Input

const ManageMovies = () => {
    const { token, apiUrl } = useContext(Context)

    const [movies, setMovies] = useState([])
    const [moviesMaster, setMoviesMaster] = useState([])
    const [movieId, setMovieId] = useState()
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [counter, setCounter] = useState(0)
    const [actors, setActors] = useState([])
    const [directors, setDirectors] = useState([])
    const [producers, setProducers] = useState([])
    const [genres, setGenres] = useState([])
    const [languages, setLanguages] = useState([])
    const [availability, setAvailability] = useState("perpetual")
    const [cardImageLoading, setCardImageLoading] = useState(false)
    const [detailImageLoading, setDetailImageLoading] = useState(false)
    const [cardImageUrl, setCardImageUrl] = useState()
    const [detailImageUrl, setDetailImageUrl] = useState()
    const [monetizationModel, setMonetizationModel] = useState("")
    const [trailerUploading, setTrailerUploading] = useState(false)
    const [movieUploading, setMovieUploading] = useState(false)
    const [trailerUploadProgress, setTrailerUploadProgress] = useState(0)
    const [movieUploadProgress, setMovieUploadProgress] = useState(0)
    const [srt, setSrt] = useState()
    const [contentExists, setContentExists] = useState(false)
    const [trailerExists, setTrailerExists] = useState(false)
    const [srtUploading, setSrtUploading] = useState(false)
    const [srtUploadProgress, setSrtUploadProgress] = useState(0)

    const [form] = Form.useForm()

    useEffect(() => {
        Fetch(
            `${apiUrl}/movies`,
            token,
            (result) => {
                const movies = convertIdToKey(result.movies)
                movies.forEach(movie => {
                    if (typeof (movie['genre']) === 'string') {
                        movie['genre'] = [movie['genre']]
                    }
                })
                setMovies(movies)
                setMoviesMaster(movies)
            }
        )

        Fetch(`${apiUrl}/genres`, token, (result) => {
            setGenres(result.genres)
        })

        Fetch(`${apiUrl}/languages`, token, (result) => {
            setLanguages(result.languages)
        })

        Fetch(`${apiUrl}/artists`, token, (result) => {
            setActors(result.artists)
        })

        Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
            setDirectors(result.directors)
            setProducers(result.producers)
        })
    }, [apiUrl, token, counter])

    useEffect(() => {
        if (!movieId) {
            return
        }

        Fetch(`${apiUrl}/movies/${movieId}`, token, (result) => {
            const { startDate, endDate, ...others } = result
            if (startDate && endDate) {
                form.setFieldsValue({ ...others, startDate: moment(startDate).utcOffset(startDate), endDate: moment(endDate).utcOffset(endDate) })
            } else if (startDate) {
                form.setFieldsValue({ ...others, startDate: moment(startDate).utcOffset(startDate) })
            } else {
                form.setFieldsValue(others)
            }

            setDetailImageUrl(result.detailImage)
            setCardImageUrl(result.cardImage)
            setAvailability(result.availability)
            setMonetizationModel(result.model)
            setContentExists(result.playbackUrl ? true : false)
            setTrailerExists(result.trailer ? true : false)
        })
    }, [apiUrl, token, movieId])

    const cardImageUploadButton = (
        <div>
            {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (320x180)</div>
        </div>
    )

    const detailImageUploadButton = (
        <div>
            {detailImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (1920x1080)</div>
        </div>
    )

    const beforeImageUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('Only JPG and PNG files are allowed')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must be smaller than 2M')
        }

        return isJpgOrPng && isLt2M
    }

    const handleCardImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setCardImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setCardImageLoading(false)
            setCardImageUrl(info.file.response.url)
            form.setFieldsValue({ cardImage: info.file.response.url })
        }
    }

    const handleDetailImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setDetailImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setDetailImageLoading(false)
            setDetailImageUrl(info.file.response.url)
            form.setFieldsValue({ detailImage: info.file.response.url })
        }
    }

    const showConfirmDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete this movie?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/movies/${id}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Movie deleted successfully'
                            })
                            setCounter(counter + 1)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting movie',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    const handleEdit = async () => {
        try {
            const values = await form.validateFields()
            Put(
                `${apiUrl}/movies/${movieId}`,
                token,
                { ...values, subtitles: srt ? srt : '' },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Edited successfully'
                        })
                        form.resetFields()
                        setEditModalVisible(false)
                        setMovieId(null)
                    }
                },
                () => {
                    notification.error({
                        message: 'Error updating movie',
                        description: 'Please check logs for more details'
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Edit Movie Error',
                description: 'Required fields are incorrect/missing'
            })
        }
    }

    const handleChange = (e, type) => {
        const file = e.target.files[0]
        let setUploadProgressFn

        if (type === "movie") {
            setMovieUploading(true)
            setUploadProgressFn = setMovieUploadProgress
        } else if (type === "trailer") {
            setTrailerUploading(true)
            setUploadProgressFn = setTrailerUploadProgress
        } else if (type === "srt") {
            setSrtUploading(true)
            setUploadProgressFn = setSrtUploadProgress
        }

        const upload = new tus.Upload(file, {
            endpoint: 'https://transcode.aaonxt.com/files',
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            onError: (error) => {
                console.log("Failed because: " + error)
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                setUploadProgressFn(percentage)
                console.log(bytesUploaded, bytesTotal, percentage + "%")
            },
            onSuccess: () => {
                if (type === "movie") {
                    Post(
                        `https://transcode.aaonxt.com/submitJob`,
                        token,
                        {
                            id: movieId,
                            loc: upload.url.split('/').slice(-1)[0],
                            media_type: 'movies'
                        },
                        result => {
                            console.log(result)
                        },
                        () => {
                            console.error(`Error transcoding content`)
                        }
                    )
                }

                if (type === "trailer") {
                    Post(
                        `https://transcode.aaonxt.com/submitJob`,
                        token,
                        {
                            id: movieId,
                            loc: upload.url.split('/').slice(-1)[0],
                            media_type: 'movie_trailer'
                        },
                        result => {
                            console.log(result)
                        },
                        () => {
                            console.error(`Error transcoding content`)
                        }
                    )
                }

                if (type === "srt") {
                    setSrt(upload.url)
                }

                console.log("Download %s from %s", upload.file.name, upload.url)
            }
        })

        upload.start()
    }

    const handleSearchChange = (movieId) => {
        Fetch(
            `${apiUrl}/movies/${movieId}`,
            token,
            (result) => {
                const movies = convertIdToKey([result])
                movies.forEach(movie => {
                    if (typeof (movie['genre']) === 'string') {
                        movie['genre'] = [movie['genre']]
                    }
                })
                setMovies(movies)
            }
        )
    }

    return (
        <div className="manage-movies-wrapper">
            <Select
                placeholder="Search Movie"
                showSearch
                allowClear
                onChange={handleSearchChange}
                onClear={() => setMovies(moviesMaster)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {moviesMaster.map(m => (
                    <Option value={m.key}>{m.title}</Option>
                ))}
            </Select>
            <Table dataSource={movies}>
                <Column title="Name" dataIndex="title" key="title" align="center" />
                <Column title="Language" dataIndex="language" key="language" align="center" />
                <Column
                    title="Genres"
                    dataIndex="genre"
                    key="genre"
                    align="center"
                    render={genre => (
                        <>
                            {genre.map(g => (
                                <Tag color="blue" key={g}>
                                    {g}
                                </Tag>
                            ))}
                        </>
                    )}
                />
                <Column title="Availability" dataIndex="availability" key="availability" align="center" />
                <Column title="Model" dataIndex="model" key="model" align="center" />
                <Column title="Trailer Exists" key="trailer" render={(_, record) => record.trailer ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column title="Content Exists" key="playbackUrl" render={(_, record) => record.playbackUrl ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column title="Subtitles Exists" key="subtitles" render={(_, record) => record.subtitles ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column
                    title="Actions"
                    key="action"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => { setMovieId(record.key); setEditModalVisible(true); setTrailerUploading(false); setTrailerUploadProgress(0); setMovieUploading(false); setMovieUploadProgress(0) }}>Edit</a>
                            <a onClick={() => { showConfirmDelete(record.key) }}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <CloudDownloadOutlined className="export-btn-only" onClick={() => handleExportClick(apiUrl, token, 'movies')} />
            <Modal
                title="Edit Movie"
                visible={editModalVisible}
                okText="Update"
                onOk={handleEdit}
                onCancel={() => setEditModalVisible(false)}
            >
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <Input size="middle" />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                        <TextArea />
                    </Form.Item>
                    <Form.Item name="language" label="Language" rules={[{ required: true }]}>
                        <Select
                            placeholder="Choose a language"
                            showSearch
                        >
                            {languages.map(language => (
                                <Option value={language.name}>{language.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="genre" label="Genre / Category" rules={[{ required: true }]}>
                        <Select
                            placeholder="Choose a genre"
                            mode="multiple"
                            showSearch
                        >
                            {genres.map(genre => (
                                <Option value={genre.name}>{genre.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="maturity" label="Maturity" rules={[{ required: true }]}>
                        <Select
                            placeholder="Choose maturity"
                        >
                            <Option value="U">U (Kids)</Option>
                            <Option value="UA">UA (Teens)</Option>
                            <Option value="A">A (Adults)</Option>
                            <Option value="S">S (Adults)</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="year" label="Year" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Card title="Cast &amp; Crew" className="form-card">
                        <Form.Item name="actors" label="Actors" className="form-item-selector" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                mode="multiple"
                                className="form-card-selector"
                            >
                                {actors.map(actor => (
                                    <Option key={actor.key} value={actor.name}>{actor.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="directors" label="Directors" className="form-item-selector" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                mode="multiple"
                                className="form-card-selector"
                            >
                                {directors.map(director => (
                                    <Option key={director.key} value={director.name}>{director.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="producers" label="Producers" className="form-item-selector" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                mode="multiple"
                            >
                                {producers.map(producer => (
                                    <Option key={producer.key} value={producer.name}>{producer.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Card>
                    <Card title="Availability" className="form-card">
                        <Form.Item name="availability" label="Availability" rules={[{ required: true }]}>
                            <Radio.Group onChange={(e) => setAvailability(e.target.value)}>
                                <Radio value="perpetual">Perpetual</Radio>
                                <Radio value="restricted">Restricted</Radio>
                                <Radio value="unpublished">Unpublished</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {(availability === "restricted") && (
                            <>
                                <Form.Item
                                    name="startDate"
                                    label="Start Date"
                                    className="form-item-selector"
                                    rules={[{ required: true }]}
                                >
                                    <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                                </Form.Item>
                                <Form.Item
                                    name="endDate"
                                    label="End Date"
                                    className="form-item-selector"
                                    format="YYYY-MM-DD HH:mm:ss"
                                >
                                    <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                                </Form.Item>
                            </>
                        )}
                    </Card>
                    <Form.Item name="cardImage" label="Card Image" rules={[{ required: true }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleCardImageUploadStatusChange}
                        >
                            {cardImageUrl ? <img src={cardImageUrl} style={{ width: '100%' }} /> : cardImageUploadButton}
                        </Upload>
                    </Form.Item>
                    <Form.Item name="detailImage" label="Detail Image" rules={[{ required: true }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleDetailImageUploadStatusChange}
                        >
                            {detailImageUrl ? <img src={detailImageUrl} style={{ width: '100%' }} /> : detailImageUploadButton}
                        </Upload>
                    </Form.Item>
                    <Card title="Monetization" className="form-card">
                        <Form.Item label="Model" name="model">
                            <Radio.Group onChange={(e) => setMonetizationModel(e.target.value)}>
                                <Radio value="ppv">PPV</Radio>
                                <Radio value="subscription">Subscription</Radio>
                                <Radio value="free">Free</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {(monetizationModel === "ppv") && (
                            <>
                                <Form.Item name="streamPeriod" label="Streaming Period" className="form-item-selector" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Select streaming period"
                                        className="form-card-selector"
                                    >
                                        <Option value="48">48h (2 days)</Option>
                                        <Option value="72">72h (3 days)</Option>
                                        <Option value="168">168h (7 days)</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="subscriberPrice" label="Price for Subscribers" className="form-item-selector" rules={[{ required: true }]}>
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name="nonSubscriberPrice" label="Price for Non Subscribers" className="form-item-selector" rules={[{ required: true }]}>
                                    <InputNumber />
                                </Form.Item>
                            </>
                        )}
                    </Card>
                    <Card title="Stream" className="form-card">
                        <Form.Item name="duration" label="Duration" className="form-item-selector" rules={[{ required: true }]}>
                            <InputNumber placeholder="Enter duration in seconds" />
                        </Form.Item>
                        <Form.Item name="skipIntroStart" label="Skip Intro Start" className="form-item-selector">
                            <InputNumber placeholder="Enter start time in seconds" />
                        </Form.Item>
                        <Form.Item name="skipIntroEnd" label="Skip Intro End" className="form-item-selector">
                            <InputNumber placeholder="Enter end time in seconds" />
                        </Form.Item>
                    </Card>
                </Form>
                <Form.Item label={trailerExists ? "Edit Trailer" : "Upload Trailer"}>
                    {trailerUploading ? <Progress percent={trailerUploadProgress} /> : <input type="file" onChange={(e) => handleChange(e, "trailer")} accept="video/mp4" />}
                </Form.Item>
                <Form.Item label={contentExists ? "Edit Movie" : "Upload Movie"}>
                    {movieUploading ? <Progress percent={movieUploadProgress} /> : <input type="file" onChange={(e) => handleChange(e, "movie")} accept="video/mp4" />}
                </Form.Item>
                <Form.Item label="Upload Subtitles">
                    {srtUploading ? <Progress percent={srtUploadProgress} /> : <input type="file" onChange={(e) => handleChange(e, "srt")} accept="text/plain" />}
                </Form.Item>
            </Modal>
        </div>
    )
}

export default ManageMovies
