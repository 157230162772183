import { Form, Button, Input, Modal, Table, notification, InputNumber } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, MinusCircleOutlined, PlusOutlined, CloudDownloadOutlined } from '@ant-design/icons'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'

const { Column } = Table

const ManagePlans = () => {
    const { token, apiUrl } = useContext(Context)

    const [plans, setPlans] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const [form] = Form.useForm()

    useEffect(() => {
        Fetch(`${apiUrl}/plans`, token, (result) => {
            const plans = convertIdToKey(result.plans)
            setPlans(plans)
        })
    }, [apiUrl, token, confirmLoading])

    const handleSubmit = async () => {
        try {
            await setConfirmLoading(true)
            await form.validateFields()
            Post(
                `${apiUrl}/plans`,
                token,
                {
                    name: form.getFieldValue('name'),
                    price: form.getFieldValue('price'),
                    duration: form.getFieldValue('duration'),
                    features: form.getFieldValue('features')
                },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Plan created'
                        })
                    }
                    form.resetFields()
                    setModalVisible(false)
                },
                () => {
                    notification.error({
                        message: 'Add Plan Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Add Plan Error',
                description: 'Required fields are incorrect/missing'
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    const handleDelete = async (id) => {
        try {
            await setConfirmLoading(true)
            Delete(
                `${apiUrl}/plans/${id}`,
                token,
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Plan deleted successfully'
                        })
                    }
                },
                () => {
                    notification.error({
                        message: 'Delete Plan Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } finally {
            setConfirmLoading(false)
        }
    }

    return (
        <div className="manage-plans-wrapper">
            <Table dataSource={plans}>
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Price" dataIndex="price" key="price" align="center" />
                <Column title="Duration (days)" dataIndex="duration" key="duration" align="center" />
                <Column
                    title="Actions"
                    key="action"
                    align="center"
                    render={(text, record) => (
                        <a onClick={() => handleDelete(record.key)}>Delete</a>
                    )}
                />
            </Table>
            <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-plan-btn" onClick={() => setModalVisible(true)} />
            <CloudDownloadOutlined className="export-btn" onClick={() => handleExportClick(apiUrl, token, 'plans')} />
            <Modal
                title="Add Plan"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Submit"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form form={form} layout="vertical" initialValues={{ gender: "M" }}>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="price" label="Price" rules={[{ required: true }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="duration" label="Duration" rules={[{ required: true }]}>
                        <InputNumber placeholder="Specify number of days" />
                    </Form.Item>
                    <Form.List
                        name="features"
                        rules={[
                            {
                                validator: async (_, features) => {
                                    if (!features || features.length < 2)
                                        return Promise.reject(new Error('At least 2 features'))
                                }
                            }
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 ? 'Features' : ''}
                                        required={false}
                                        key={field.key}
                                    >
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input feature or delete this field.",
                                                }
                                            ]}
                                            noStyle
                                        >
                                            <Input style={{ width: '95%' }} />
                                        </Form.Item>
                                        {fields.length > 1 ? (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-btn"
                                                onClick={() => remove(field.name)}
                                            />
                                        ) : null}
                                    </Form.Item>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                    >
                                        Add feature
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </div>
    )
}

export default ManagePlans
