import { useState, useEffect, useContext } from 'react'
import {
    Table,
    InputNumber,
    Modal,
    Select,
    Radio,
    Form,
    Upload,
    Space,
    notification,
    message
} from 'antd'
import { PlusCircleTwoTone, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { Context } from '../../context'
import { Delete, Fetch, Patch, Put } from '../../fetch'
import { convertIdToKey, removeUnpublished } from '../../utils'
import Column from 'antd/lib/table/Column'

const { Option } = Select
const { confirm } = Modal

const ManageBanners = () => {
    const { apiUrl, token } = useContext(Context)

    const [page, setPage] = useState()
    const [banners, setBanners] = useState()
    const [bannerType, setBannerType] = useState()
    const [modalVisible, setModalVisible] = useState(false)
    const [modalType, setModalType] = useState()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [movies, setMovies] = useState([])
    const [series, setSeries] = useState([])
    const [albums, setAlbums] = useState([])
    const [bannerImageUrl, setBannerImageUrl] = useState()
    const [bannerImageLoading, setBannerImageLoading] = useState(false)
    const [counter, setCounter] = useState(0)
    const [promoId, setPromoId] = useState()

    const [form] = Form.useForm()

    const bannerImageUploadButton = (
        <div>
            {bannerImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    const beforeImageUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('Only JPG and PNG files are allowed')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must be smaller than 2M')
        }

        return isJpgOrPng && isLt2M
    }

    const handleBannerImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setBannerImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setBannerImageLoading(false)
            setBannerImageUrl(info.file.response.url)
            form.setFieldsValue({ bannerImage: info.file.response.url })
        }
    }

    const handleChange = (page) => {
        setPage(page)
    }

    useEffect(() => {
        if (page) {
            Fetch(`${apiUrl}/promos?filter=${page}`, token, (result) => {
                const banners = convertIdToKey(result.promos)
                setBanners(banners)
            })
        }

        Fetch(`${apiUrl}/movies`, token, (result) => {
            const movies = removeUnpublished(convertIdToKey(result.movies))
            setMovies(movies)
        })

        Fetch(`${apiUrl}/series`, token, (result) => {
            const series = removeUnpublished(convertIdToKey(result.series))
            setSeries(series)
        })

        Fetch(`${apiUrl}/albums`, token, (result) => {
            const albums = removeUnpublished(convertIdToKey(result.albums))
            setAlbums(albums)
        })
    }, [apiUrl, token, page, counter])

    const handleSubmit = async () => {
        setConfirmLoading(true)
        try {
            const values = await form.validateFields()
            const fn = modalType === 'Add' ? Patch : Put
            fn(
                modalType === 'Add' ? `${apiUrl}/promos` : `${apiUrl}/promos/${promoId}`,
                token,
                { ...values, page },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Banners updated'
                        })
                    }
                    form.resetFields()
                    setModalVisible(false)
                    setConfirmLoading(false)
                    setCounter(counter + 1)
                },
                () => {
                    notification.error({
                        message: 'Edit Banners Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } catch (err) {
            notification.error({
                message: 'Edit Banners Error',
                description: 'Required fields are incorrect/missing'
            })
        } finally {
            setConfirmLoading(false)
            setBannerImageUrl("")
        }
    }

    const extractName = (record) => {
        switch (record['promoType']) {
            case 'movie':
                for (const movie of movies) {
                    if (movie.key === record.movie) {
                        return movie.title
                    }
                }
                break
            case 'series':
                for (const s of series) {
                    if (s.key === record.series) {
                        return s.title
                    }
                }
                break
            case 'album':
                for (const album of albums) {
                    if (album.key === record.album) {
                        return album.title
                    }
                }
                break
            case 'static':
                return 'Static'
        }
    }

    const extractNameByType = (type, record) => {
        switch (type) {
            case 'movies':
                for (const movie of movies) {
                    if (movie.key === record.movie) {
                        return movie.title
                    }
                }
                break
            case 'series':
                for (const s of series) {
                    if (s.key === record.series) {
                        return s.title
                    }
                }
                break
            case 'albums':
                for (const album of albums) {
                    if (album.key === record.album) {
                        return album.title
                    }
                }
                break
            case 'static':
                return 'Static'
        }
    }

    const showConfirmDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete this banner?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/promos/${id}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Success',
                                description: 'Promo deleted successfully'
                            })
                            setCounter(counter + 1)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting promo',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    const showEditModal = (record) => {
        setModalType("Edit")
        setPromoId(record.key)
        form.setFieldsValue(record)
        setBannerImageUrl(record.bannerImage)
        setModalVisible(true)

        if (page === 'home') {
            setBannerType(record.promoType)
        }
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Select Page"
                showSearch
                onChange={handleChange}
            >
                <Option value="home">Home</Option>
                <Option value="movies">Movies</Option>
                <Option value="series">Series</Option>
                <Option value="albums">Albums</Option>
            </Select>
            <Table dataSource={banners}>
                {page === 'home' && <Column title="Promo Type" dataIndex="promoType" key="promoType" align="center" />}
                {page === 'home' && <Column title="Name" render={(value, record) => extractName(record)} align="center" />}
                {page !== 'home' && <Column title="Name" render={(value, record) => extractNameByType(page, record)} align="center" />}
                <Column title="Position" dataIndex="position" key="position" align="center" />
                <Column title="Banner" dataIndex="bannerImage" key="bannerImage" align="center" render={(value) => <img src={value} style={{ width: '250px' }} />} />
                <Column
                    title="Actions"
                    key="action"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => { showEditModal(record) }}>Edit</a>
                            <a onClick={() => showConfirmDelete(record.key)}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-series-btn" onClick={() => { setModalVisible(true); setModalType("Add") }} />
            <Modal
                title="Modify Banner"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Submit"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form layout="vertical" form={form}>
                    {page === "home" && (
                        <Form.Item name="promoType" label="Type" rules={[{ required: true }]}>
                            <Radio.Group className="content-type-option" onChange={(e) => setBannerType(e.target.value)}>
                                <Radio value="movie">Movie</Radio>
                                <Radio value="series">Series</Radio>
                                <Radio value="album">Album</Radio>
                                <Radio value="song">Static</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                    {(page === "movies" || bannerType === "movie") && (
                        <Form.Item name="movie" label="Select Movie" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                className="form-card-selector"
                            >
                                {movies.map(movie => (
                                    <Option key={movie.key} value={movie.key}>{movie.title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {(page === "series" || bannerType === "series") && (
                        <Form.Item name="series" label="Select Series" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                className="form-card-selector"
                            >
                                {series.map(s => (
                                    <Option key={s.key} value={s.key}>{s.title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {(page === "albums" || bannerType === "album") && (
                        <Form.Item name="album" label="Select Album" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                className="form-card-selector"
                            >
                                {albums.map(album => (
                                    <Option key={album.key} value={album.key}>{album.title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item name="position" label="Position" rules={[{ required: true }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="bannerImage" label="Banner" rules={[{ required: true }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleBannerImageUploadStatusChange}
                        >
                            {bannerImageUrl ? <img src={bannerImageUrl} style={{ width: '100%' }} /> : bannerImageUploadButton}
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ManageBanners
