import {
    Select,
    Table,
    Input,
    Space,
    Modal,
    Form,
    InputNumber,
    notification,
    Upload,
    Progress,
    message
} from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined, CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons'
import { useContext, useEffect, useState } from 'react'
import * as tus from 'tus-js-client'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey } from '../../utils'

const { Option } = Select
const { Column } = Table
const { confirm } = Modal

const ManageSongs = () => {
    const { apiUrl, token } = useContext(Context)

    const [albums, setAlbums] = useState([])
    const [album, setAlbum] = useState()
    const [songs, setSongs] = useState([])
    const [song, setSong] = useState()
    const [actors, setActors] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [contentExists, setContentExists] = useState(false)
    const [cardImageLoading, setCardImageLoading] = useState(false)
    const [cardImageUrl, setCardImageUrl] = useState("")


    const [form] = Form.useForm()

    const cardImageUploadButton = (
        <div>
            {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (320x180)</div>
        </div>
    )

    useEffect(() => {
        Fetch(`${apiUrl}/albums`, token, (result) => {
            const albums = convertIdToKey(result.albums)
            setAlbums(albums)
        })

        Fetch(`${apiUrl}/artists`, token, (result) => {
            setActors(result.artists)
        })
    }, [apiUrl, token])

    const handleChange = (album) => {
        setAlbum(album)
        Fetch(`${apiUrl}/albums/${album}/songs`, token, result => {
            const songs = convertIdToKey(result.songs)
            setSongs(songs)
        })
    }

    const showEditModal = (record) => {
        setSong(record)
        setContentExists(record.playbackUrl ? true : false)
        setModalVisible(true)
        form.setFieldsValue(record)
    }

    const handleSubmit = async () => {
        setConfirmLoading(true)
        try {
            const values = await form.validateFields()
            Post(
                `${apiUrl}/albums/${album}/songs`,
                token,
                {
                    ...values,
                    old: song
                },
                (result) => {
                    if (result.success) {
                        form.resetFields()

                        notification.success({
                            message: 'Success',
                            description: 'Song edited'
                        })
                        setModalVisible(false)
                        handleChange(album)
                    }
                },
                () => {
                    notification.error({
                        message: "Error",
                        description: "Please check console for more details"
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: "Error",
                description: "Required fields incorrect/missing"
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    const handleUploadChange = (e) => {
        const file = e.target.files[0]

        setUploading(true)

        const upload = new tus.Upload(file, {
            endpoint: 'https://transcode.aaonxt.com/files',
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            onError: (error) => {
                console.log("Failed because: " + error)
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                setUploadProgress(percentage)
                console.log(bytesUploaded, bytesTotal, percentage + "%")
            },
            onSuccess: () => {
                Post(
                    `https://transcode.aaonxt.com/submitJob`,
                    token,
                    {
                        id: `${album}_${song.position}`,
                        loc: upload.url.split('/').slice(-1)[0],
                        media_type: 'song'
                    },
                    result => {
                        console.log(result)
                    },
                    () => {
                        console.error(`Error transcoding content`)
                    }
                )
                console.log("Download %s from %s", upload.file.name, upload.url)
            }
        })

        upload.start()
    }

    const confirmDelete = (record) => {
        confirm({
            title: 'Are you sure you want to delete this song?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/albums/${album}/songs?position=${record.position}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Song deleted successfully'
                            })
                            handleChange(album)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting song',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    const beforeImageUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('Only JPG and PNG files are allowed')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must be smaller than 2M')
        }

        return isJpgOrPng && isLt2M
    }

    const handleCardImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setCardImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setCardImageLoading(false)
            setCardImageUrl(info.file.response.url)
            form.setFieldsValue({ cardImage: info.file.response.url })
        }
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Select Album"
                showSearch
                onChange={handleChange}
            >
                {albums.map(album => (
                    <Option value={album.key}>{album.title}</Option>
                ))}
            </Select>
            <Table dataSource={songs}>
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Position" dataIndex="position" key="position" align="center" />
                <Column title="Content Exists" key="trailer" render={(_, record) => record.playbackUrl ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column
                    title="Actions"
                    key="actions"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => showEditModal(record)}>Edit</a>
                            <a onClick={() => confirmDelete(record)}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <Modal
                title="Edit Song"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Update"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form form={form}>
                    <Form.Item name="name" label="Name" className="form-item-selector" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="position" label="Position" className="form-item-selector" rules={[{ required: true }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="actors" label="Actors" className="form-item-selector" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            mode="multiple"
                            className="form-card-selector"
                        >
                            {actors.map(actor => (
                                <Option key={actor.key} value={actor.name}>{actor.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="cardImage" label="Card Image" rules={[{ required: true }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleCardImageUploadStatusChange}
                        >
                            {cardImageUrl ? <img src={cardImageUrl} style={{ width: '100%' }} /> : cardImageUploadButton}
                        </Upload>
                    </Form.Item>
                    <Form.Item name="duration" label="Duration" className="form-item-selector" rules={[{ required: true }]}>
                        <InputNumber placeholder="Enter duration in seconds" />
                    </Form.Item>
                </Form>
                <Form.Item label={contentExists ? "Edit Song" : "Upload Song"}>
                    {uploading ? <Progress percent={uploadProgress} /> : <input type="file" onChange={handleUploadChange} accept="video/mp4" />}
                </Form.Item>
            </Modal>
        </div>
    )
}

export default ManageSongs
