import { Table, Select } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { CloudDownloadOutlined } from '@ant-design/icons'

import './styles.css'

import { Context } from '../../context'
import { Fetch } from '../../fetch'
import { convertIdToKey, convertSubscriptions, handleExportClick } from '../../utils'
import moment from 'moment'

const { Column } = Table
const { Option } = Select

const ManageUsers = () => {
    const { token, apiUrl } = useContext(Context)

    const [users, setUsers] = useState([])
    const [usersMaster, setUsersMaster] = useState([])

    useEffect(() => {
        Fetch(`${apiUrl}/users`, token, (result) => {
            const users = convertSubscriptions(convertIdToKey(result.users))
            users.forEach(user => {
                if (!user.mobile) {
                    user.mobile = "-"
                }

                if (!user.email) {
                    user.email = "-"
                }
            })
            setUsers(users)
            setUsersMaster(users)
        })
    }, [apiUrl, token])

    const handleSearchChange = (userId) => {
        if (!userId)
            return

        setUsers(users.filter(u => u.key === userId))
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Search User by Email"
                showSearch
                allowClear
                onChange={handleSearchChange}
                onClear={() => setUsers(usersMaster)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {usersMaster.map(u => (
                    <Option value={u.key}>{u.email}</Option>
                ))}
            </Select>
            <Select
                placeholder="Search User by Mobile"
                showSearch
                allowClear
                onChange={handleSearchChange}
                onClear={() => setUsers(usersMaster)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {usersMaster.map(u => (
                    <Option value={u.key}>{u.mobile}</Option>
                ))}
            </Select>
            <Table dataSource={users}>
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Email" dataIndex="email" key="email" align="center" />
                <Column title="Mobile" dataIndex="mobile" key="mobile" align="center" />
                <Column title="Created At" dataIndex="createdAt" key="createdAt" align="center" defaultSortOrder='descend' sorter={(a, b) => moment(a.createdAt) - moment(b.createdAt)} />
                <Column title="Subscription" dataIndex="subscription" key="subscription" align="center" />
            </Table>
            <CloudDownloadOutlined className="export-btn-only" onClick={() => handleExportClick(apiUrl, token, 'users')} />
        </div>
    )
}

export default ManageUsers
