import { Layout, Menu } from 'antd'
import { useState } from 'react'

import './styles.css'

import SideNav from '../SideNav'
import Dashboard from '../Dashboard'
import AddContent from '../AddContent'
import ManageArtists from '../ManageArtists'
import ManageMediaHouses from '../ManageMediaHouses'
import ManagePlans from '../ManagePlans'
import ManangeMovies from '../ManageMovies'
import ManageGenres from '../ManageGenres'
import ManageUsers from '../ManageUsers'
import ManageSeries from '../ManageSeries'
import ManageEpisodes from '../ManageEpisodes'
import ManageOrders from '../ManageOrders'
import ManageAlbums from '../ManageAlbums'
import ManageSongs from '../ManageSongs'
import ManageBanners from '../ManageBanners'
import ManagePlaylists from '../ManagePlaylists'
import ManageDiscounts from '../ManageDiscounts'
import ManageLanguages from '../ManageLanguages'
import ManageEmployees from '../ManageEmployees'
import ManagePermissions from '../ManagePermissions'

const { Header, Content } = Layout


const AdminModule = () => {
    const content = {
        "add-content": <AddContent />,
        "dashboard": <Dashboard />,
        "manage-artists": <ManageArtists />,
        "manage-media-houses": <ManageMediaHouses />,
        "manage-plans": <ManagePlans />,
        "manage-movies": <ManangeMovies />,
        "manage-genres": <ManageGenres />,
        "manage-users": <ManageUsers />,
        "manage-series": <ManageSeries />,
        "manage-episodes": <ManageEpisodes />,
        "manage-orders": <ManageOrders />,
        "manage-albums": <ManageAlbums />,
        "manage-songs": <ManageSongs />,
        "banners": <ManageBanners />,
        "playlists": <ManagePlaylists />,
        "manage-discounts": <ManageDiscounts />,
        "manage-languages": <ManageLanguages />,
        "manage-employees": <ManageEmployees />,
        "manage-permissions": <ManagePermissions />,
    }
    const [selectedTab, setSelectedTab] = useState("dashboard")

    const logout = async () => {
        await sessionStorage.removeItem('access_token')
        window.location.reload()
    }

    return (
        <>
            <Layout>
                <Header>
                    <div className="header-content">
                        <img src="/images/logo.jpeg" alt="rvr-logo" className="header-logo" />
                        <Menu theme="dark" mode="horizontal">
                            <Menu.Item key="1" onClick={logout}>Logout</Menu.Item>
                        </Menu>
                    </div>
                </Header>
                <Layout>
                    <SideNav updateTab={(tab) => setSelectedTab(tab)} />
                    <Content>
                        {content[selectedTab] || "You do not have permissions to view this module"}
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default AdminModule
