import {
    Form,
    Input,
    InputNumber,
    Button,
    Tabs,
    Radio,
    Select,
    Card,
    DatePicker,
    Upload,
    notification,
    message
} from 'antd'
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { useContext, useEffect, useState } from 'react'

import './styles.css'
import { Context } from '../../context'
import { Fetch, Post } from '../../fetch'
import { convertIdToKey } from '../../utils'

const { TabPane } = Tabs
const { TextArea } = Input
const { Option } = Select

const AddContent = () => {
    const [activeTab, setActiveTab] = useState("1")
    const [contentType, setContentType] = useState("movie")
    const [availability, setAvailability] = useState("perpetual")
    const [monetizationModel, setMonetizationModel] = useState("")
    const [series, setSeries] = useState([])
    const [albums, setAlbums] = useState([])

    const { token, apiUrl } = useContext(Context)

    const [contentInfoForm] = Form.useForm()
    const [castCrewAvailabilityForm] = Form.useForm()
    const [posterBannersForm] = Form.useForm()
    const [monetizationStreamingForm] = Form.useForm()

    const [actors, setActors] = useState([])
    const [directors, setDirectors] = useState([])
    const [producers, setProducers] = useState([])
    const [genres, setGenres] = useState([])
    const [languages, setLanguages] = useState([])

    const [cardImageLoading, setCardImageLoading] = useState(false)
    const [detailImageLoading, setDetailImageLoading] = useState(false)
    const [cardImageUrl, setCardImageUrl] = useState()
    const [detailImageUrl, setDetailImageUrl] = useState()

    const cardImageUploadButton = (
        <div>
            {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (320x180)</div>
        </div>
    )

    const detailImageUploadButton = (
        <div>
            {detailImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (1920x1080)</div>
        </div>
    )

    const beforeImageUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('Only JPG and PNG files are allowed')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must be smaller than 2M')
        }

        return isJpgOrPng && isLt2M
    }

    const handleCardImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setCardImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setCardImageLoading(false)
            setCardImageUrl(info.file.response.url)
            posterBannersForm.setFieldsValue({ cardImage: info.file.response.url })
        }
    }

    const handleDetailImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setDetailImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setDetailImageLoading(false)
            setDetailImageUrl(info.file.response.url)
            posterBannersForm.setFieldsValue({ detailImage: info.file.response.url })
        }
    }

    useEffect(() => {
        Fetch(`${apiUrl}/artists`, token, (result) => {
            setActors(result.artists)
        })

        Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
            setDirectors(result.directors)
            setProducers(result.producers)
        })

        Fetch(`${apiUrl}/genres`, token, (result) => {
            setGenres(result.genres)
        })

        Fetch(`${apiUrl}/languages`, token, (result) => {
            setLanguages(result.languages)
        })

        Fetch(`${apiUrl}/series`, token, (result) => {
            const series = convertIdToKey(result.series)
            setSeries(series)
        })

        Fetch(`${apiUrl}/albums`, token, (result) => {
            const albums = convertIdToKey(result.albums)
            setAlbums(albums)
        })
    }, [apiUrl, token])

    const onFinish = async (e) => {
        e.preventDefault()

        let contentInfo, castCrewAvailability, posterBanners, monetizationStreaming
        let valid = true

        try {
            contentInfo = await contentInfoForm.validateFields()
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Content Info Error',
                description: 'Required fields are incorrect/missing'
            })
            valid = false
        }

        try {
            castCrewAvailability = await castCrewAvailabilityForm.validateFields()
            console.log(castCrewAvailability);
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Cast & Crew Error',
                description: 'Required fields are incorrect/missing'
            })
            valid = false
        }

        try {
            posterBanners = await posterBannersForm.validateFields()
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Posters & Banner Error',
                description: 'Required fields are incorrect/missing'
            })
            valid = false
        }

        try {
            monetizationStreaming = await monetizationStreamingForm.validateFields()
        } catch (err) {
            console.log(err)
            notification.error({
                message: 'Monetization & Stream Error',
                description: 'Required fields are incorrect/missing'
            })
            valid = false
        }

        if (valid && contentType === "movie") {
            Post(
                `${apiUrl}/movies`,
                token,
                {
                    ...contentInfo,
                    ...castCrewAvailability,
                    ...posterBanners,
                    ...monetizationStreaming
                },
                (result) => {
                    if (result.success) {
                        contentInfoForm.resetFields()
                        castCrewAvailabilityForm.resetFields()
                        posterBannersForm.resetFields()
                        monetizationStreamingForm.resetFields()

                        notification.success({
                            message: 'Success',
                            description: 'Content created'
                        })
                    }
                },
                () => {
                    notification.error({
                        message: "Error",
                        description: "Please check console for more details"
                    })
                }
            )
        } else if (valid && contentType === "series") {
            Post(
                `${apiUrl}/series/${contentInfo.series}/episodes`,
                token,
                {
                    ...contentInfo,
                    ...castCrewAvailability,
                    ...posterBanners,
                    ...monetizationStreaming
                },
                (result) => {
                    if (result.success) {
                        contentInfoForm.resetFields()
                        castCrewAvailabilityForm.resetFields()
                        posterBannersForm.resetFields()
                        monetizationStreamingForm.resetFields()

                        notification.success({
                            message: 'Success',
                            description: 'Content created'
                        })
                    }
                },
                () => {
                    notification.error({
                        message: "Error",
                        description: "Please check console for more details"
                    })
                }
            )
        } else if (valid && contentType === 'song') {
            Post(
                `${apiUrl}/albums/${contentInfo.album}/songs`,
                token,
                {
                    ...contentInfo,
                    ...castCrewAvailability,
                    ...posterBanners,
                    ...monetizationStreaming
                },
                (result) => {
                    if (result.success) {
                        contentInfoForm.resetFields()
                        castCrewAvailabilityForm.resetFields()
                        posterBannersForm.resetFields()
                        monetizationStreamingForm.resetFields()

                        notification.success({
                            message: 'Success',
                            description: 'Content created'
                        })
                    }
                },
                () => {
                    notification.error({
                        message: "Error",
                        description: "Please check console for more details"
                    })
                }
            )
        }
    }

    return (
        <div className="add-content-wrapper">
            <h3>Add Content</h3>
            <Tabs activeKey={activeTab} onChange={(tab) => setActiveTab(tab)} className="add-content-tabs">
                <TabPane tab="Content Information" key="1">
                    <Form layout="vertical" form={contentInfoForm}>
                        <Radio.Group className="content-type-option" value={contentType} onChange={(e) => setContentType(e.target.value)}>
                            <Radio value="movie">Movie</Radio>
                            <Radio value="series">Episode</Radio>
                            <Radio value="song">Song</Radio>
                        </Radio.Group>
                        {(contentType === "movie") && (
                            <>
                                <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                                    <Input size="middle" />
                                </Form.Item>
                                <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                                    <TextArea />
                                </Form.Item>
                                <Form.Item name="language" label="Language" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Choose a language"
                                        showSearch
                                    >
                                        {languages.map(language => (
                                            <Option value={language.name}>{language.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="genre" label="Genre / Category" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Choose a genre"
                                        mode="multiple"
                                        showSearch
                                    >
                                        {genres.map(genre => (
                                            <Option value={genre.name}>{genre.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="maturity" label="Maturity" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Choose maturity"
                                    >
                                        <Option value="U">U (Kids)</Option>
                                        <Option value="UA">UA (Teens)</Option>
                                        <Option value="A">A (Adults)</Option>
                                        <Option value="S">S (Adults)</Option>
                                        <Option value="13+">13+</Option>
                                        <Option value="16+">16+</Option>
                                        <Option value="18+">18+</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="year" label="Year" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </>
                        )}
                        {(contentType === "series" && series.length > 0) && (
                            <>
                                <Form.Item name="series" label="Series" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Select Series"
                                    >
                                        {series.map(s => (
                                            <Option value={s.key}>{s.title}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="season" label="Season" rules={[{ required: true }]}>
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name="number" label="Episode #" rules={[{ required: true }]}>
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                                    <TextArea />
                                </Form.Item>
                            </>
                        )}
                        {contentType === 'song' && (
                            <>
                                <Form.Item name="album" label="Album" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Select Album"
                                    >
                                        {albums.map(album => (
                                            <Option value={album.key}>{album.title}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="position" label="Position" rules={[{ required: true }]}>
                                    <InputNumber />
                                </Form.Item>
                            </>
                        )}
                    </Form>
                    <Button className="next-btn" onClick={() => setActiveTab("2")}>Next</Button>
                </TabPane>
                <TabPane tab="Cast, Crew &amp; Availability" key="2">
                    <Form form={castCrewAvailabilityForm}>
                        <Card title="Cast &amp; Crew" className="form-card">
                            <Form.Item name="actors" label="Actors" className="form-item-selector" rules={[{ required: contentType !== 'series' }]}>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    className="form-card-selector"
                                >
                                    {actors.map(actor => (
                                        <Option key={actor.key} value={actor.name}>{actor.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="directors" label="Directors" className="form-item-selector" rules={[{ required: contentType === 'movie' }]}>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    className="form-card-selector"
                                >
                                    {directors.map(director => (
                                        <Option key={director.key} value={director.name}>{director.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="producers" label="Producers" className="form-item-selector" rules={[{ required: contentType === 'movie' }]}>
                                <Select
                                    showSearch
                                    mode="multiple"
                                >
                                    {producers.map(producer => (
                                        <Option key={producer.key} value={producer.name}>{producer.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Card>
                        <Card title="Availability" className="form-card">
                            <Form.Item name="availability" label="Availability" rules={[{ required: contentType === 'movie' }]}>
                                <Radio.Group onChange={(e) => setAvailability(e.target.value)} disabled={contentType === 'series' || contentType === 'song'}>
                                    <Radio value="perpetual">Perpetual</Radio>
                                    <Radio value="restricted">Restricted</Radio>
                                    <Radio value="unpublished">Unpublished</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {(availability === "restricted") && (
                                <>
                                    <Form.Item
                                        name="startDate"
                                        label="Start Date"
                                        className="form-item-selector"
                                        rules={[{ required: true }]}
                                    >
                                        <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                                    </Form.Item>
                                    <Form.Item
                                        name="endDate"
                                        label="End Date"
                                        className="form-item-selector"
                                        format="YYYY-MM-DD HH:mm:ss"
                                    >
                                        <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                                    </Form.Item>
                                </>
                            )}
                        </Card>
                    </Form>
                    <Button className="next-btn" onClick={() => setActiveTab("3")}>Next</Button>
                </TabPane>
                <TabPane tab="Poster &amp; Banners" key="3">
                    <Form form={posterBannersForm}>
                        <Form.Item name="cardImage" label="Card Image" rules={[{ required: contentType !== 'song' }]}>
                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                action={`${apiUrl}/uploads`}
                                headers={{
                                    authorization: `Bearer ${token}`
                                }}
                                beforeUpload={beforeImageUpload}
                                onChange={handleCardImageUploadStatusChange}
                            >
                                {cardImageUrl ? <img src={cardImageUrl} style={{ width: '100%' }} /> : cardImageUploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item name="detailImage" label="Detail Image" rules={[{ required: contentType === 'movie' }]}>
                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                action={`${apiUrl}/uploads`}
                                headers={{
                                    authorization: `Bearer ${token}`
                                }}
                                beforeUpload={beforeImageUpload}
                                onChange={handleDetailImageUploadStatusChange}
                            >
                                {detailImageUrl ? <img src={detailImageUrl} style={{ width: '100%' }} /> : detailImageUploadButton}
                            </Upload>
                        </Form.Item>
                    </Form>
                    <Button className="next-btn" onClick={() => setActiveTab("4")}>Next</Button>
                </TabPane>
                <TabPane tab="Monetization &amp; Stream" key="4">
                    <Form form={monetizationStreamingForm}>
                        <Card title="Monetization" className="form-card">
                            <Form.Item label="Model" name="model">
                                <Radio.Group onChange={(e) => setMonetizationModel(e.target.value)} disabled={contentType !== 'movie'}>
                                    <Radio value="ppv">PPV</Radio>
                                    <Radio value="subscription">Subscription</Radio>
                                    <Radio value="free">Free</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {(monetizationModel === "ppv") && (
                                <>
                                    <Form.Item name="streamPeriod" label="Streaming Period" className="form-item-selector" rules={[{ required: true }]}>
                                        <Select
                                            placeholder="Select streaming period"
                                            className="form-card-selector"
                                        >
                                            <Option value="48">48h (2 days)</Option>
                                            <Option value="72">72h (3 days)</Option>
                                            <Option value="168">168h (7 days)</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="subscriberPrice" label="Price for Subscribers" className="form-item-selector" rules={[{ required: true }]}>
                                        <InputNumber />
                                    </Form.Item>
                                    <Form.Item name="nonSubscriberPrice" label="Price for Non Subscribers" className="form-item-selector" rules={[{ required: true }]}>
                                        <InputNumber />
                                    </Form.Item>
                                </>
                            )}
                        </Card>
                        <Card title="Stream" className="form-card">
                            <Form.Item name="duration" label="Duration" className="form-item-selector" rules={[{ required: true }]}>
                                <InputNumber placeholder="Enter duration in seconds" />
                            </Form.Item>
                            <Form.Item name="skipIntroStart" label="Skip Intro Start" className="form-item-selector">
                                <InputNumber placeholder="Enter start time in seconds" disabled={contentType === 'song'} />
                            </Form.Item>
                            <Form.Item name="skipIntroEnd" label="Skip Intro End" className="form-item-selector">
                                <InputNumber placeholder="Enter end time in seconds" disabled={contentType === 'song'} />
                            </Form.Item>
                        </Card>
                        <Button onClick={onFinish} className="finish-btn">
                            Finish
                        </Button>
                    </Form>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default AddContent
