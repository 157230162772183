import {
    Select,
    Table,
    Space,
    Form,
    InputNumber,
    Input,
    Card,
    Upload,
    message,
    notification,
    Modal,
    Progress
} from 'antd'
import {
    LoadingOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    CheckCircleFilled,
    CloseCircleOutlined
} from '@ant-design/icons'
import { useContext, useEffect, useState } from 'react'
import * as tus from 'tus-js-client'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey } from '../../utils'

const { Option } = Select
const { Column } = Table
const { TextArea } = Input
const { confirm } = Modal

const ManageEpisodes = () => {
    const { apiUrl, token } = useContext(Context)

    const [series, setSeries] = useState([])
    const [seriesId, setSeriesId] = useState()
    const [episodes, setEpisodes] = useState([])
    const [episode, setEpisode] = useState()
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [actors, setActors] = useState([])
    const [directors, setDirectors] = useState([])
    const [producers, setProducers] = useState([])
    const contentType = "series"
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [contentExists, setContentExists] = useState(false)
    const [srt, setSrt] = useState()
    const [srtUploading, setSrtUploading] = useState(false)
    const [srtUploadProgress, setSrtUploadProgress] = useState(0)

    const [form] = Form.useForm()

    const [cardImageLoading, setCardImageLoading] = useState(false)
    const [detailImageLoading, setDetailImageLoading] = useState(false)
    const [cardImageUrl, setCardImageUrl] = useState()
    const [detailImageUrl, setDetailImageUrl] = useState()

    const cardImageUploadButton = (
        <div>
            {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (320x180)</div>
        </div>
    )

    const detailImageUploadButton = (
        <div>
            {detailImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (1920x1080)</div>
        </div>
    )

    const beforeImageUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('Only JPG and PNG files are allowed')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must be smaller than 2M')
        }

        return isJpgOrPng && isLt2M
    }

    const handleCardImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setCardImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setCardImageLoading(false)
            setCardImageUrl(info.file.response.url)
            form.setFieldsValue({ cardImage: info.file.response.url })
        }
    }

    const handleDetailImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setDetailImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setDetailImageLoading(false)
            setDetailImageUrl(info.file.response.url)
            form.setFieldsValue({ detailImage: info.file.response.url })
        }
    }

    useEffect(() => {
        Fetch(`${apiUrl}/series`, token, (result) => {
            const series = convertIdToKey(result.series)
            setSeries(series)
        })

        Fetch(`${apiUrl}/artists`, token, (result) => {
            setActors(result.artists)
        })

        Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
            setDirectors(result.directors)
            setProducers(result.producers)
        })
    }, [apiUrl, token])

    const handleChange = (seriesId) => {
        setSeriesId(seriesId)
        Fetch(`${apiUrl}/series/${seriesId}/episodes`, token, result => {
            const episodes = convertIdToKey(result.episodes)
            setEpisodes(episodes)
        })
    }

    const showEditModal = (record) => {
        setEpisode(record)
        setModalVisible(true)
        form.setFieldsValue(record)
        setCardImageUrl(record.cardImage)
        setDetailImageUrl(record.detailImage)
        setContentExists(record.playbackUrl ? true : false)
    }

    const handleSubmit = async () => {
        setConfirmLoading(true)
        try {
            const values = await form.validateFields()
            Post(
                `${apiUrl}/series/${seriesId}/episodes`,
                token,
                {
                    ...values,
                    old: episode,
                    subtitles: srt ? srt : ''
                },
                (result) => {
                    if (result.success) {
                        form.resetFields()

                        notification.success({
                            message: 'Success',
                            description: 'Epsiode edited'
                        })
                        setModalVisible(false)
                        handleChange(seriesId)
                    }
                },
                () => {
                    notification.error({
                        message: "Error",
                        description: "Please check console for more details"
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: "Error",
                description: "Required fields incorrect/missing"
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    const handleUploadChange = (e, type) => {
        const file = e.target.files[0]
        let setUploadProgressFn

        if (type === "episode") {
            setUploading(true)
            setUploadProgressFn = setUploadProgress
        } else if (type === "srt") {
            setSrtUploading(true)
            setUploadProgressFn = setSrtUploadProgress
        }

        const upload = new tus.Upload(file, {
            endpoint: 'https://transcode.aaonxt.com/files',
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            onError: (error) => {
                console.log("Failed because: " + error)
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                setUploadProgressFn(percentage)
                console.log(bytesUploaded, bytesTotal, percentage + "%")
            },
            onSuccess: () => {
                if (type === "episode") {
                    Post(
                        `https://transcode.aaonxt.com/submitJob`,
                        token,
                        {
                            id: `${seriesId}_${episode.season}_${episode.number}`,
                            loc: upload.url.split('/').slice(-1)[0],
                            media_type: 'episode'
                        },
                        result => {
                            console.log(result)
                        },
                        () => {
                            console.error(`Error transcoding content`)
                        }
                    )
                } else if (type === "srt") {
                    setSrt(upload.url)
                }

                console.log("Download %s from %s", upload.file.name, upload.url)
            }
        })

        upload.start()
    }

    const showConfirmDelete = (record) => {
        confirm({
            title: 'Are you sure you want to delete this episode?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/series/${seriesId}/episodes?season=${record['season']}&number=${record['number']}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Episode deleted successfully'
                            })
                            handleChange(seriesId)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting episode',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Select Series"
                showSearch
                onChange={handleChange}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {series.map(s => (
                    <Option value={s.key}>{s.title}</Option>
                ))}
            </Select>
            <Table dataSource={episodes}>
                <Column title="Season" dataIndex="season" key="season" align="center" />
                <Column title="Episode #" dataIndex="number" key="number" align="center" />
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Content Exists" key="playbackUrl" render={(_, record) => record.playbackUrl ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column title="Subtitles Exists" key="subtitles" render={(_, record) => record.subtitles ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column
                    title="Actions"
                    key="actions"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => showEditModal(record)}>Edit</a>
                            <a onClick={() => showConfirmDelete(record)}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <Modal
                title="Edit Episode"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Update"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item name="season" label="Season" rules={[{ required: true }]}>
                        <InputNumber disabled />
                    </Form.Item>
                    <Form.Item name="number" label="Episode #" rules={[{ required: true }]}>
                        <InputNumber disabled />
                    </Form.Item>
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                        <TextArea />
                    </Form.Item>
                    <Card title="Cast &amp; Crew" className="form-card">
                        <Form.Item name="actors" label="Actors" className="form-item-selector" rules={[{ required: contentType !== 'series' }]}>
                            <Select
                                showSearch
                                mode="multiple"
                                className="form-card-selector"
                            >
                                {actors.map(actor => (
                                    <Option key={actor.key} value={actor.name}>{actor.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="directors" label="Directors" className="form-item-selector" rules={[{ required: contentType !== 'series' }]}>
                            <Select
                                showSearch
                                mode="multiple"
                                className="form-card-selector"
                            >
                                {directors.map(director => (
                                    <Option key={director.key} value={director.name}>{director.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="producers" label="Producers" className="form-item-selector" rules={[{ required: contentType !== 'series' }]}>
                            <Select
                                showSearch
                                mode="multiple"
                            >
                                {producers.map(producer => (
                                    <Option key={producer.key} value={producer.name}>{producer.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Card>
                    <Form.Item name="cardImage" label="Card Image" rules={[{ required: true }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleCardImageUploadStatusChange}
                        >
                            {cardImageUrl ? <img src={cardImageUrl} style={{ width: '100%' }} /> : cardImageUploadButton}
                        </Upload>
                    </Form.Item>
                    <Form.Item name="detailImage" label="Detail Image" rules={[{ required: contentType !== 'series' }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleDetailImageUploadStatusChange}
                        >
                            {detailImageUrl ? <img src={detailImageUrl} style={{ width: '100%' }} /> : detailImageUploadButton}
                        </Upload>
                    </Form.Item>
                    <Form.Item name="duration" label="Duration" className="form-item-selector" rules={[{ required: true }]}>
                        <InputNumber placeholder="Enter duration in seconds" />
                    </Form.Item>
                    <Form.Item name="skipIntroStart" label="Skip Intro Start" className="form-item-selector">
                        <InputNumber placeholder="Enter start time in seconds" />
                    </Form.Item>
                    <Form.Item name="skipIntroEnd" label="Skip Intro End" className="form-item-selector">
                        <InputNumber placeholder="Enter end time in seconds" />
                    </Form.Item>
                    <Form.Item name="creditsStart" label="Credits Start" className="form-item-selector">
                        <InputNumber placeholder="Enter start time in seconds" />
                    </Form.Item>
                </Form>
                <Form.Item label={contentExists ? "Edit Episode" : "Upload Episode"}>
                    {uploading ? <Progress percent={uploadProgress} /> : <input type="file" onChange={(e) => handleUploadChange(e, "episode")} accept="video/mp4" />}
                </Form.Item>
                <Form.Item label="Upload Subtitles">
                    {srtUploading ? <Progress percent={srtUploadProgress} /> : <input type="file" onChange={(e) => handleUploadChange(e, "srt")} accept="text/plain" />}
                </Form.Item>
            </Modal>
        </div>
    )
}

export default ManageEpisodes
