import { Form, Input, Modal, Table, Space, Select, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, ExclamationCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'

const { Column } = Table
const { confirm } = Modal
const { Option } = Select

const ManageGenres = () => {
    const { token, apiUrl } = useContext(Context)

    const [genres, setGenres] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [counter, setCounter] = useState(0)
    const [genresMaster, setGenresMaster] = useState([])

    const [form] = Form.useForm()

    useEffect(() => {
        Fetch(`${apiUrl}/genres`, token, (result) => {
            const genres = convertIdToKey(result.genres)
            setGenres(genres)
            setGenresMaster(genres)
        })
    }, [apiUrl, token, counter])

    const handleSubmit = async () => {
        try {
            setConfirmLoading(true)
            await form.validateFields()
            Post(
                `${apiUrl}/genres`,
                token,
                {
                    name: form.getFieldValue('name'),
                },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Genre created'
                        })
                    }
                    form.resetFields()
                    setModalVisible(false)
                    setCounter(counter + 1)
                },
                () => {
                    notification.error({
                        message: 'Add Genre Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Add Genre Error',
                description: 'Required fields are incorrect/missing'
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    const confirmDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete this genre?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/genres/${id}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Genre deleted successfully'
                            })
                            setCounter(counter + 1)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting genre',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    const handleSearchChange = (genreId) => {
        if (!genreId)
            return

        setGenres(genres.filter(g => g.key === genreId))
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Search Genre"
                showSearch
                allowClear
                onChange={handleSearchChange}
                onClear={() => setGenres(genresMaster)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {genresMaster.map(g => (
                    <Option value={g.key}>{g.name}</Option>
                ))}
            </Select>
            <Table dataSource={genres}>
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column
                    title="Actions"
                    key="actions"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => confirmDelete(record.key)}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-genre-btn" onClick={() => setModalVisible(true)} />
            <CloudDownloadOutlined className="export-btn" onClick={() => handleExportClick(apiUrl, token, 'genres')} />
            <Modal
                title="Add Genre"
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Submit"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ManageGenres
