import { useContext, useEffect, useState } from "react"
import { Space, Table, Modal, Form, Input, notification } from 'antd'
import {
    CheckCircleFilled,
    CloseCircleOutlined,
    PlusCircleTwoTone,
    ExclamationCircleOutlined
} from '@ant-design/icons'

import { Context } from "../../context"
import { Fetch, Post, Delete, Put } from "../../fetch"
import { convertIdToKey } from '../../utils'

const { Column } = Table
const { confirm } = Modal

const ManageEmployees = () => {
    const {apiUrl, token} = useContext(Context)

    const [employees, setEmployees] = useState([])
    const [counter, setCounter] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)
    const [modalType, setModalType] = useState("")
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [employeeId, setEmployeeId] = useState()

    const [form] = Form.useForm()

    useEffect(() => {
        Fetch(`${apiUrl}/admins`, token, (result) => {
            const employees = convertIdToKey(result.employees)
            setEmployees(employees)
        })
    }, [apiUrl, token, counter])

    const showEditModal = (record) => {
        setModalType("Edit")
        setEmployeeId(record.key)
        const {password, ...others} = record
        form.setFieldsValue(others)
        setModalVisible(true)
    }

    const showConfirmDelete = (record) => {
        confirm({
            title: 'Are you sure you want to delete this employee?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/admins/${record.key}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Employee deleted successfully'
                            })
                            setCounter(counter + 1)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting employee',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    const handleSubmit = async () => {
        setConfirmLoading(true)
        try {
            const fields = await form.validateFields()
            const fn = modalType === 'Add' ? Post : Put
            fn(
                modalType === 'Add' ? `${apiUrl}/admins` : `${apiUrl}/admins/${employeeId}`,
                token,
                fields,
                (result) => {
                    if (result.success) {
                        form.resetFields()

                        notification.success({
                            message: 'Success',
                            description: modalType === 'Add' ? 'Employee created' : 'Employee updated'
                        })
                        setModalVisible(false)
                        setCounter(counter + 1)
                    }
                },
                () => {
                    notification.error({
                        message: "Error",
                        description: "Please check console for more details"
                    })
                }
            )
        } catch (err) {
            notification.error({
                message: "Error",
                description: "Required fields incorrect/missing"
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    return (
        <div className="manage-episodes-wrapper">
            <Table dataSource={employees}>
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Email" dataIndex="email" key="email" align="center" />
                <Column title="SuperAdmin" dataIndex="superadmin" key="superadmin" align="center" render={superadmin => superadmin ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column 
                    title="Actions"
                    key="actions"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => showEditModal(record)}>Edit</a>
                            {!record.superadmin && <a onClick={() => showConfirmDelete(record)}>Delete</a>}
                        </Space>
                    )}
                />
            </Table>
            <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-series-btn" onClick={() => {setModalVisible(true); setModalType("Add")}} />
            <Modal
                title={`${modalType} Employee`}
                visible={modalVisible}
                onOk={handleSubmit}
                onCancel={() => {setModalVisible(false); form.resetFields()}}
                confirmLoading={confirmLoading}>
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="Email" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{ required: modalType === 'Add' }]}>
                        {modalType === 'Add' ? <Input type="password" /> : <Input type="password" placeholder="leave blank to use existing password" />}
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ManageEmployees
