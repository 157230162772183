import {
    Form,
    Input,
    Modal,
    Select,
    Space,
    Table,
    Tag,
    Radio,
    DatePicker,
    Upload,
    message,
    notification,
    Progress
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined, CloudDownloadOutlined, CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons'
import * as tus from 'tus-js-client'
import moment from 'moment'

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Delete, Put } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import TextArea from 'antd/lib/input/TextArea'

const { Column } = Table
const { Option } = Select
const { confirm } = Modal

const ManageSeries = () => {
    const { token, apiUrl } = useContext(Context)

    const [series, setSeries] = useState([])
    const [genres, setGenres] = useState([])
    const [languages, setLanguages] = useState([])
    const [actors, setActors] = useState([])
    const [directors, setDirectors] = useState([])
    const [producers, setProducers] = useState([])
    const [availability, setAvailability] = useState("perpetual")
    const [cardImageLoading, setCardImageLoading] = useState(false)
    const [detailImageLoading, setDetailImageLoading] = useState(false)
    const [cardImageUrl, setCardImageUrl] = useState("")
    const [detailImageUrl, setDetailImageUrl] = useState("")
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [modalType, setModalType] = useState("")
    const [seriesId, setSeriesId] = useState()
    const [counter, setCounter] = useState(0)
    const [trailerUploading, setTrailerUploading] = useState(false)
    const [trailerUploadProgress, setTrailerUploadProgress] = useState(0)
    const [trailer, setTrailer] = useState()
    const [seriesMaster, setSeriesMaster] = useState([])

    const [form] = Form.useForm()

    const cardImageUploadButton = (
        <div>
            {cardImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (320x180)</div>
        </div>
    )

    const detailImageUploadButton = (
        <div>
            {detailImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload (1920x1080)</div>
        </div>
    )

    useEffect(() => {
        Fetch(`${apiUrl}/series`, token, (result) => {
            const series = convertIdToKey(result.series)
            setSeries(series)
            setSeriesMaster(series)
        })

        Fetch(`${apiUrl}/genres`, token, (result) => {
            setGenres(result.genres)
        })

        Fetch(`${apiUrl}/languages`, token, (result) => {
            setLanguages(result.languages)
        })

        Fetch(`${apiUrl}/artists`, token, (result) => {
            setActors(result.artists)
        })

        Fetch(`${apiUrl}/mediaHouses`, token, (result) => {
            setDirectors(result.directors)
            setProducers(result.producers)
        })
    }, [apiUrl, token, counter])

    const handleSubmit = async () => {
        if (modalType === 'Edit') {
            setConfirmLoading(true)
            try {
                const fields = await form.validateFields()
                Put(
                    `${apiUrl}/series/${seriesId}`,
                    token,
                    { ...fields, trailer },
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Success',
                                description: 'Series updated'
                            })
                        }
                        form.resetFields()
                        setModalVisible(false)
                        setConfirmLoading(false)
                        setCounter(counter + 1)
                    },
                    () => {
                        notification.error({
                            message: 'Edit Series Error',
                            description: 'Check console for more details'
                        })
                    }
                )
            } catch (err) {
                notification.error({
                    message: 'Edit Series Error',
                    description: 'Required fields are incorrect/missing'
                })
            }
            return
        }

        try {
            setConfirmLoading(true)
            const fields = await form.validateFields()
            Post(
                `${apiUrl}/series`,
                token,
                { ...fields, trailer },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Series created'
                        })
                    }
                    form.resetFields()
                    setModalVisible(false)
                    setCounter(counter + 1)
                },
                () => {
                    notification.error({
                        message: 'Add Series Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } catch (err) {
            console.error(err)
            notification.error({
                message: 'Add Series Error',
                description: 'Required fields are incorrect/missing'
            })
        } finally {
            setConfirmLoading(false)
        }
    }

    const beforeImageUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('Only JPG and PNG files are allowed')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must be smaller than 2M')
        }

        return isJpgOrPng && isLt2M
    }

    const handleCardImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setCardImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setCardImageLoading(false)
            setCardImageUrl(info.file.response.url)
            form.setFieldsValue({ cardImage: info.file.response.url })
        }
    }

    const handleDetailImageUploadStatusChange = (info) => {
        if (info.file.status === 'uploading') {
            setDetailImageLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setDetailImageLoading(false)
            setDetailImageUrl(info.file.response.url)
            form.setFieldsValue({ detailImage: info.file.response.url })
        }
    }

    const showEditModal = (id) => {
        setModalType("Edit")
        setModalVisible(true)
        Fetch(`${apiUrl}/series/${id}`, token, (result) => {
            const { startDate, endDate, ...others } = result
            if (startDate && endDate) {
                form.setFieldsValue({ ...others, startDate: moment(startDate).utcOffset(startDate), endDate: moment(endDate).utcOffset(endDate) })
            } else if (startDate) {
                form.setFieldsValue({ ...others, startDate: moment(startDate).utcOffset(startDate) })
            } else {
                form.setFieldsValue(others)
            }
            setSeriesId(id)
            setCardImageUrl(result.cardImage)
            setDetailImageUrl(result.detailImage)
        })
    }

    const confirmDelete = (id) => {
        confirm({
            title: 'Are you sure you want to delete this series?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                Delete(
                    `${apiUrl}/series/${id}`,
                    token,
                    (result) => {
                        if (result.success) {
                            notification.success({
                                message: 'Succes',
                                description: 'Series deleted successfully'
                            })
                            setCounter(counter + 1)
                        }
                    },
                    () => {
                        notification.error({
                            message: 'Error deleting series',
                            description: 'Please check console for more details'
                        })
                    }
                )
            }
        })
    }

    const handleChange = (e, type) => {
        const file = e.target.files[0]

        setTrailerUploading(true)

        const upload = new tus.Upload(file, {
            endpoint: 'https://transcode.aaonxt.com/files',
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
                filename: file.name,
                filetype: file.type
            },
            onError: (error) => {
                console.log("Failed because: " + error)
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                setTrailerUploadProgress(percentage)
                console.log(bytesUploaded, bytesTotal, percentage + "%")
            },
            onSuccess: () => {
                if (type === "trailer") {
                    Post(
                        `https://transcode.aaonxt.com/submitJob`,
                        token,
                        {
                            id: seriesId,
                            loc: upload.url.split('/').slice(-1)[0],
                            media_type: 'series_trailer'
                        },
                        result => {
                            console.log(result)
                        },
                        () => {
                            console.error(`Error transcoding content`)
                        }
                    )
                }

                console.log("Download %s from %s", upload.file.name, upload.url)
            }
        })

        upload.start()
    }

    const handleSearchChange = (seriesId) => {
        Fetch(
            `${apiUrl}/series/${seriesId}`,
            token,
            (result) => {
                const series = convertIdToKey([result])
                series.forEach(s => {
                    if (typeof (s['genre']) === 'string') {
                        s['genre'] = [s['genre']]
                    }
                })
                setSeries(series)
            }
        )
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Search Series"
                showSearch
                allowClear
                onChange={handleSearchChange}
                onClear={() => setSeries(seriesMaster)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {seriesMaster.map(s => (
                    <Option value={s.key}>{s.title}</Option>
                ))}
            </Select>
            <Table dataSource={series}>
                <Column title="Name" dataIndex="title" key="title" align="center" />
                <Column title="Language" dataIndex="language" key="language" align="center" />
                <Column
                    title="Genres"
                    dataIndex="genre"
                    key="genre"
                    align="center"
                    render={genre => (
                        <>
                            {genre.map(g => (
                                <Tag color="blue" key={g}>
                                    {g}
                                </Tag>
                            ))}
                        </>
                    )}
                />
                <Column title="Availability" dataIndex="availability" key="availability" align="center" />
                <Column title="Model" dataIndex="model" key="model" align="center" />
                <Column title="Trailer Exists" key="trailer" render={(_, record) => record.trailer ? <CheckCircleFilled /> : <CloseCircleOutlined />} />
                <Column
                    title="Actions"
                    key="actions"
                    align="center"
                    render={(text, record) => (
                        <Space size="middle">
                            <a onClick={() => showEditModal(record.key)}>Edit</a>
                            <a onClick={() => confirmDelete(record.key)}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-series-btn" onClick={() => { setModalVisible(true); setModalType("Add") }} />
            <CloudDownloadOutlined className="export-btn" onClick={() => handleExportClick(apiUrl, token, 'series')} />
            <Modal
                title={modalType + " Series"}
                visible={modalVisible}
                onOk={handleSubmit}
                okText="Submit"
                onCancel={() => setModalVisible(false)}
                confirmLoading={confirmLoading}
            >
                <Form form={form} layout="vertical" initialValues={{ availability: "perpetual" }}>
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                        <TextArea />
                    </Form.Item>
                    <Form.Item name="language" label="Language" rules={[{ required: true }]}>
                        <Select
                            placeholder="Choose a language"
                            showSearch
                        >
                            {languages.map(language => (
                                <Option value={language.name}>{language.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="genre" label="Genre / Category" rules={[{ required: true }]}>
                        <Select
                            placeholder="Choose a genre"
                            mode="multiple"
                            showSearch
                        >
                            {genres.map(genre => (
                                <Option value={genre.name}>{genre.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="actors" label="Actors" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            mode="multiple"
                            className="form-card-selector"
                        >
                            {actors.map(actor => (
                                <Option key={actor.key} value={actor.name}>{actor.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="directors" label="Directors" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            mode="multiple"
                            className="form-card-selector"
                        >
                            {directors.map(director => (
                                <Option key={director.key} value={director.name}>{director.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="producers" label="Producers" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            mode="multiple"
                        >
                            {producers.map(producer => (
                                <Option key={producer.key} value={producer.name}>{producer.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="availability" label="Availability" rules={[{ required: true }]}>
                        <Radio.Group onChange={(e) => setAvailability(e.target.value)}>
                            <Radio value="perpetual">Perpetual</Radio>
                            <Radio value="restricted">Restricted</Radio>
                            <Radio value="unpublished">Unpublished</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {(availability === "restricted") && (
                        <>
                            <Form.Item
                                name="startDate"
                                label="Start Date"
                                className="form-item-selector"
                                rules={[{ required: true }]}
                            >
                                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                            </Form.Item>
                            <Form.Item
                                name="endDate"
                                label="End Date"
                                className="form-item-selector"
                                format="YYYY-MM-DD HH:mm:ss"
                            >
                                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item label="Model" name="model" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value="subscription">Subscription</Radio>
                            <Radio value="free">Free</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="cardImage" label="Card Image" rules={[{ required: true }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleCardImageUploadStatusChange}
                        >
                            {cardImageUrl ? <img src={cardImageUrl} style={{ width: '100%' }} /> : cardImageUploadButton}
                        </Upload>
                    </Form.Item>
                    <Form.Item name="detailImage" label="Detail Image" rules={[{ required: true }]}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            action={`${apiUrl}/uploads`}
                            headers={{
                                authorization: `Bearer ${token}`
                            }}
                            beforeUpload={beforeImageUpload}
                            onChange={handleDetailImageUploadStatusChange}
                        >
                            {detailImageUrl ? <img src={detailImageUrl} style={{ width: '100%' }} /> : detailImageUploadButton}
                        </Upload>
                    </Form.Item>
                </Form>
                <Form.Item label="Upload Trailer">
                    {trailerUploading ? <Progress percent={trailerUploadProgress} /> : <input type="file" onChange={(e) => handleChange(e, "trailer")} accept="video/mp4" />}
                </Form.Item>
            </Modal>
        </div>
    )
}

export default ManageSeries
